//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import the component
import Treeselect from '@riophae/vue-treeselect';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  // register the component
  components: { Treeselect },
  props: {
    value: { type: [String, Number], default: null },
    data: { type: Array, default: () => [] }
  },
  data() {
    return {
      // define options
      normalizer(node) {
        return {
          label: node.name
        };
      }
    };
  },
  async created() {
    try {
      this.loading = true;
      // this.options = this.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelect(node, instanceId) {
      this.$emit('select', node);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClearText() {
      this.onSelect(null);
      return true;
    }
  }
};
