var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("treeselect", {
        attrs: {
          options: _vm.data,
          placeholder: _vm.$t("Please select"),
          "disable-branch-nodes": true,
          normalizer: _vm.normalizer,
          beforeClearAll: _vm.onClearText,
          noOptionsText: _vm.$t("No options available.")
        },
        on: { select: _vm.onSelect },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }